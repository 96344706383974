import React from "react"
import { navigate } from "gatsby"
import Logo from "../images/logo.svg"
import { Container, Row, Col } from "react-bootstrap"
import pin from "../images/pin.svg"
import phone from "../images/phone.svg"
import mail from "../images/mail.svg"
import search from "../images/search.svg"
import directions from "../images/directions.svg"
import phone1 from "../images/phone1.svg"
import ButtonRound from "./ButtonRound"
import { openGoogleMapsNav } from "../commonFunctions"

const Header = () => (
  <header className="App-header">
    <div className="btn-search">
      <img src={search} alt="search" className="material-icons md-18 td" />
    </div>
    <img
      src={Logo}
      className="top-logo"
      alt="logo"
      onClick={e => navigate("/")}
    />
    <div className="times">
      <span>Montag - Freitag 08.00 - 18.00 Uhr</span>
      <span>Samstags 08.00 - 12.00 Uhr</span>
    </div>
    <div className="top-sections">
      <div className="hs hs-left">
        <div className="baseline">
          <img src={phone} alt="phone" className="md-18 td" />{" "}
          <span className="m-l-h">0621 - 324 706 68</span>
        </div>
        <div className="top-buttons">
          <ButtonRound>
            <a href="mailto:info@baumschinen-ma.de" className="btnwraplnk">
              <img src={mail} alt="mail" className="md-18 td" />
            </a>
          </ButtonRound>
          <ButtonRound onClick={() => openGoogleMapsNav("49.529316,8.485607")}>
            <img src={directions} alt="directions" className="md-18 td" />
          </ButtonRound>
          <ButtonRound>
            <a href="tel:062132470668" className="btnwraplnk">
              <img src={phone} alt="phone" className="md-18 td" />
            </a>
          </ButtonRound>
        </div>
      </div>
      <div className="hs hs-right">
        <div className="adrc">
          <img src={pin} alt="pin" className="md-18 ty" />
          <div className="top-address">
            Carl-Reuther-Straße 1<br />
            68305 Mannheim
            <br />
            <span className="top-addr-ad">Im Sirius-Business-Park</span>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
