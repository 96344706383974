export const openGoogleMapsNav = (destination = "") => {
	const MAPS_BASE_URL_SEARCH = "https://www.google.com/maps/search/?api=1";
	const MAPS_BASE_URL_DIR = "https://www.google.com/maps/dir/?api=1";
	let final_url = "";

	final_url = `${MAPS_BASE_URL_DIR}&origin=Current+Location&destination=${encodeURI(
		destination
	)}&travelmode=driving`;
	if (final_url && final_url.length > 0) {
		window.open(final_url, "_blank");
	}
};
