import React from "react"

const ButtonRound = ({ onClick = null, children }) => {
	return (
		<button className="btn-round" onClick={onClick}>
			{children}
		</button>
	)
}

export default ButtonRound
