import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from "react-bootstrap"
import CookieConsent from "react-cookie-consent"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        cookieName="ga-baumschinen-ma"
      >
        Diese Website verwendet Website-Tracking-Technologien von
        Drittanbietern, um unsere Dienstleistungen anzubieten und kontinuierlich
        zu verbessern und um Werbung entsprechend den Interessen der Benutzer
        anzuzeigen. Ich bin damit einverstanden und kann meine Einwilligung
        jederzeit mit Wirkung für die Zukunft widerrufen oder ändern.
      </CookieConsent>
      <div>
        {children}
        <footer>
          <Container>
            <ul className="text-center">
              <li>
                <Link to="/imprint">Impressum</Link>
              </li>
              <li>
                <Link to="/privacy">Datenschutz</Link>
              </li>
            </ul>
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
